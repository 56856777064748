import React, { UseEffect, useState } from "react";
import ReactLoading from "react-loading";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../api/api";
import Footer from "../../components/Footer";
import Logo from "../../components/Logo";
import NavHeader from "./components/NavHeader";
import NewsMarquee from "./components/NewsMarquee";
import SliderCarousel from "./components/SliderCarousel";

const ApplicationRegister = () => {
  const [surname, setSurname] = useState("");
  const [othername, setOthername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [programmeType, setProgrammeType] = useState("");
  const [entryMode, setEntryMode] = useState("");
  const [studyMode, setStudyMode] = useState("FT");
  const [btnText, setBtnText] = useState("Register");
  const [loading, setLoading] = useState(false);

  const codee = phone.slice(0, 3).toString();

  const navi = useNavigate();

  const errorProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const warnProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const successProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const registerApplicant = async (e) => {
    e.preventDefault();
    if (password !== confirmPass) {
      return toast.warning("Passwords do not match", warnProp);
    }
    try {
      setLoading(true);
      setBtnText("Loading...");
      let res = await api.post("/admissions/apply", {
        surname,
        othername,
        email,
        password,
        entryMode,
        studyMode
      });
      console.log("Result", res);
      let id = res.data.data.application_no;
      setLoading(false);
      toast.success("Account Created", successProp);
      setBtnText("Register");
      navi("/apply/account-created", { replace: true, state: { id } });
    } catch (error) {
      toast.error(error.response.data.error.message, errorProp);
      setBtnText('Failed, try again!')
      setLoading(false);
    }
  };

  return (
    <>
      <NavHeader />
      <div class="mt-5" style={{ minHeight: "120vh", paddingTop: "1rem" }}>
        <div class="container">
          <div class="row vh-100 d-flex justify-content-center">
            <div class="col-12 align-self-center">
              <div class="auth-page">
                <div class="card auth-card shadow-lg">
                  <div class="card-body">
                    <div class="px-3">
                      <div class="text-center auth-logo-text">
                        <h4 class="mt-0 mb-3 mt-5">
                          Undergraduate Application (2024/2025)
                        </h4>
                      </div>

                      <form
                        class="form-horizontal auth-form my-4"
                        onSubmit={registerApplicant}
                      >
                        <div class="form-group">
                          <label for="username">Surname</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-user"></i>
                            </span>
                            <input
                              type="text"
                              class="form-control"
                              name="surname"
                              placeholder="Enter surname"
                              value={surname}
                              onChange={(e) => setSurname(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="username">Othername</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-user"></i>
                            </span>
                            <input
                              type="text"
                              class="form-control"
                              name="othername"
                              id="username"
                              placeholder="Enter othername"
                              value={othername}
                              onChange={(e) => setOthername(e.target.value)}
                              required
                            />
                          </div>
                        </div>

                        <div class="form-group">
                          <label for="useremail">Email</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-mail"></i>
                            </span>
                            <input
                              type="email"
                              class="form-control"
                              name="useremail"
                              id="useremail"
                              placeholder="Enter Email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </div>
                        </div>

                        <div class="form-group">
                          <label for="mo_number">Entry Mode</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-school"></i>
                            </span>
                            <select
                              class="form-control"
                              value={entryMode}
                              onChange={(e) => setEntryMode(e.target.value)}
                              required
                            >
                              <option>--Select--</option>
                              <option value="DE">Direct Entry</option>
                              <option value="UTME">UTME</option>
                            </select>
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="userpassword">Password</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-lock"></i>
                            </span>
                            <input
                              type="password"
                              class="form-control"
                              name="password"
                              id="userpassword"
                              placeholder="Enter password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="conf_password">Confirm Password</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-lock-open"></i>
                            </span>
                            <input
                              type="password"
                              class="form-control"
                              name="conf-password"
                              id="conf_password"
                              placeholder="Enter Confirm Password"
                              value={confirmPass}
                              onChange={(e) => setConfirmPass(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div class="form-group mb-0 row">
                          <div class="col-12 mt-2">
                            <button
                              class="btn btn-gradient-primary btn-round btn-block waves-effect waves-light"
                              type="submit"
                              disabled={loading}
                            >
                              {btnText} <i class="fas fa-sign-in-alt ml-1"></i>
                            </button>
                          </div>
                        </div>
                        <ToastContainer />
                      </form>
                    </div>

                    <div class="m-3 text-center text-muted">
                      <p class="">
                        Already have an account ?{" "}
                        <Link to="/apply/login" class="text-primary ml-2">
                          Log in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationRegister;
